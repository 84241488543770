<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="info d-none d-md-flex align-center justify-center"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div>
                <h1 class="display-3 white--text font-weight-medium">PAS</h1>
                <h2 class="display-1 mt-2 white--text font-weight-medium">
                  Patient Admission System
                </h2>
                <h4
                  class="subtitle-3 mt-5 white--text op-5 font-weight-regular"
                >
                  This software is part of
                  <a class="white--text" href="https://github.com/nichealpham/his-composer"
                    ><u>HIS Management System</u></a
                  >
                  for Hospital.
                </h4>
                <h6
                  class="subtitle-2 mt-1 white--text op-5 font-weight-regular"
                >
                  Copyright @2021 HIS Inc. All Rights Reserved.
                </h6>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/images/logo-icon-dark.png" />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                Sign in PAS
              </h2>
              <h6 class="subtitle-1">
                Don't have an account?
                <a href="https://github.com/nichealpham/his-composer" class>Contact HIS</a>
              </h6>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                action="/dashboards/analytical"
              >
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  counter
                  :rules="passwordRules"
                  label="Password"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  hint="At least 3 characters"
                ></v-text-field>

                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <!-- <v-checkbox
                    v-model="checkbox"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="Remember me?"
                    required
                  ></v-checkbox> -->
                  <!-- <div class="ml-auto">
                    <a href="javascript:void(0)" class="link">Forgot pwd?</a>
                  </div> -->
                </div>
                <v-alert
                  v-model="showError"
                  dismissible
                  dense
                  outlined
                  type="error"
                >
                  {{ errorMessage }}
                </v-alert>
                <v-btn
                  :disabled="!valid"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  @click="submit"
                  :loading="loading"
                  >Sign In</v-btn
                >
              </v-form>
              <!-- <div class="text-center mt-6">
                <v-chip pill class="mr-2">
                  <v-avatar left>
                    <v-btn color="blue lighten-1" class="white--text">
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn>
                  </v-avatar>Twitter
                </v-chip>
                <v-chip pill>
                  <v-avatar left>
                    <v-btn color="teal lighten-2" class="white--text">
                      <v-icon>mdi-github</v-icon>
                    </v-btn>
                  </v-avatar>Github
                </v-chip>
              </div> -->
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import AuthenticationService from "../../services/authentication";

export default {
  name: "FullLogin",
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length >= 3) || "Password contains at least 3 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    errorMessage: "",
    showError: false,
    loading: false,
    // checkbox: false,
  }),
  created() {
    if (this.$route.query.username) {
      this.email = this.$route.query.username;
    }
    if (this.$route.query.password) {
      this.password = this.$route.query.password;
    }
  },
  methods: {
    async submit() {
      this.showError = false;
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        var result = await AuthenticationService.login(
          this.email,
          this.password
        );
        this.loading = false;
        if (result.error) {
          this.errorMessage = "Invalid Login credentials!";
          this.showError = true;
          return;
        }
        this.$store.commit("SET_ACCESS_TOKEN", result.tokenID);
        this.$store.commit("SET_USER_DATA", result.authData);
        this.$router.push({ path: "/dashboard" });
      }
    },
  },
};
</script>
