import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Authen);

export default {
    login(userName, password) {
        var body = {
            userName,
            password,
        }
        return httpClient.post('/authentication/login', {}, body);
    }
}